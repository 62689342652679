import React from "react";
import MaterialUIMultiSelect from "../../../common/selects/MaterialUIMultiSelect";
import {useSelector} from "react-redux";

export default function OffersFilter({selectedOffers, selectedOperators, selectedCountries, handleChangeFilterData, inputStyle }) {

    const {activePartnerOffers, parentOffers} = useSelector(state => state.filterDataReducer.filterData);
    const isLoggedAsPartner =  useSelector(state => state.auth.isPartner) || useSelector(state => state.auth.isAdminAsPartner);

    let offers = isLoggedAsPartner ? activePartnerOffers : parentOffers;

    if (selectedCountries?.length > 0 && offers?.length > 0) {
        const availableOperatorIds = selectedCountries.flatMap(country => country.operatorIds);
        offers = offers.filter(offer => availableOperatorIds.includes(offer.operatorId));
    }
    if (selectedOperators && selectedOperators.length > 0) {
        const availableOperatorIds = selectedOperators.map(operator => operator.id);
        offers = offers.filter(offer => availableOperatorIds.includes(offer.operatorId));
    }

    const onSelect = (value) => {
        handleChangeFilterData("offers", value, true);
    };

    return (
            <MaterialUIMultiSelect dropdownData={offers}
                                   values={selectedOffers}
                                   displayedFields={["id", "offerName"]}
                                   dataField='id'
                                   label={'Офферы'}
                                   placeholder={'-'}
                                   onSelect={onSelect}
                                   isSearchable={true}
                                   styles={inputStyle}
            />
    );
}