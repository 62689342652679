import React, {Component} from "react";
import DashboardTrafficReportCard from "./DashboardTrafficReportCard";
import BasePage from "../BasePage";
import {connect} from "react-redux";
import {getDashboardChartData} from "../../../actions/dashboardActions";
import {dashboardOffers} from "../../../actions/offersActions";
import {fromDate, getServerCurrentDate, isCurrentDay, toDate} from "../../../utils/dateUtils";
import {
    CVR, INCOME,
    LAST_7_DAYS, OPERATORS, SUBSCRIBE_1,
    SUBSCRIPTIONS
} from "../../../utils/constants";
import {clearNews} from "../../../actions/newsActions";
import {loadAllActiveNetworks} from "../../../actions/advertisingNetworkActions";
import {loadAvailablePartnerBalance} from "../../../actions/partnerBalanceActions";
import PageContent from "../../common/page/PageContent";
import AdvertisingNetworksCard from "./adNetworks/DashboardBannersCard";
import {loadFiltersData} from "../../../actions/filterDataActions";


const MAX_NEWS_PER_LOAD = 5;

class DashboardPage extends Component {

    state = {
        selectedPeriod: LAST_7_DAYS,
        fromDate: fromDate(LAST_7_DAYS),
        toDate: getServerCurrentDate(),
        timeout: 0,
        selectedOperatorIds: [],
        mainOffersSwitcherChecked: true,
        disabledOfferIds: [],
        displayedLines: [SUBSCRIPTIONS, CVR, INCOME],
        filteredOffers: [],
        searchValue: "",
        beforeSearchInputAmount: 0,
        needToFilter: true,
        hasMoreNews: false
    };

    componentDidMount() {
        this.props.getDashboardChartData(fromDate(LAST_7_DAYS), getServerCurrentDate());
        this.props.dashboardOffers();
        this.props.clearNews();
        this.props.loadAllActiveNetworks();
        this.props.loadAvailablePartnerBalance();
        this.props.loadFiltersData([OPERATORS]);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.loading && !this.props.offersLoading && (this.state.needToFilter || (this.props.offersWithClicksOrRebills !== prevProps.offersWithClicksOrRebills))) {
            this.filterOffers();
        }
        if (this.props.news !== prevProps.news) {
            const numberOfLastLoadedItems = this.props.news.length - prevProps.news.length;
            this.setState({
                ...this.state,
                hasMoreNews: MAX_NEWS_PER_LOAD === numberOfLastLoadedItems
            })
        };
    }

    selectFromDate = (fromDate) => {
        if (this.props.loading) {
            return;
        }
        this.setState({fromDate: fromDate, selectedPeriod: null, needToFilter: true})
    };

    selectToDate = (toDate) => {
        if (this.props.loading) {
            return;
        }
        this.setState({toDate: toDate, selectedPeriod: null, needToFilter: true});
    };

    onCloseDatePicker = () => {
        const {fromDate, toDate} = this.state;
        if (!fromDate || !toDate) {
            return;
        }
        this.props.getDashboardChartData(fromDate, toDate);
    };

    selectPeriod = (period) => {
        if (this.props.loading) {
            return;
        }
        const startDate = fromDate(period);
        const endDate = toDate(period);
        this.setState({
            selectedPeriod: period,
            fromDate: startDate,
            toDate: endDate,
            needToFilter: true
        });
        this.props.getDashboardChartData(startDate, endDate);
    };

    selectOperator = (operator) => {
        const selectedOperatorIds = this.state.selectedOperatorIds;
        const index = selectedOperatorIds.indexOf(operator.id);
        if (index === -1) {
            selectedOperatorIds.push(operator.id);
        } else {
            selectedOperatorIds.splice(index, 1);
        }
        this.setState({
            selectedOperatorIds: selectedOperatorIds,
            needToFilter: true
        });
    };

    toggleMainOffersCheckbox = () => {
        const checked = !this.state.mainOffersSwitcherChecked;
        let disabledOfferIds = this.state.disabledOfferIds;
        if (checked) {
            disabledOfferIds = [];
        } else {
            disabledOfferIds = this.props.offers.map(offer => offer.id);
        }
        this.setState({
            mainOffersSwitcherChecked: checked,
            disabledOfferIds: disabledOfferIds
        });
    };

    toggleOfferCheckbox = (offerId) => {
        const disabledOfferIds = this.state.disabledOfferIds;
        const index = disabledOfferIds.indexOf(offerId);
        if (index === -1) {
            disabledOfferIds.push(offerId);
        } else {
            disabledOfferIds.splice(index, 1);
        }
        this.setState({
            disabledOfferIds: disabledOfferIds,
            mainOffersSwitcherChecked: disabledOfferIds.length === 0
        })
    };

    filterOffers = () => {
        const searchValue = this.state.searchValue && this.state.searchValue.toLocaleLowerCase();
        const selectedOperatorIds = this.state.selectedOperatorIds;
        let offers = this.props.offers ? this.props.offers : [];
        offers = (!selectedOperatorIds || selectedOperatorIds.length === 0)
            ? offers
            : offers.filter(offer => selectedOperatorIds.includes(offer.operatorId));
        const offersWithClicksOrRebills = this.props.offersWithClicksOrRebills;
        if (offersWithClicksOrRebills) {
            let sortedFilteredOffers = [];
            offers.forEach(offer => {
                const offerWithClicksAndRebills = offersWithClicksOrRebills.find(item => item.offerId === offer.id);
                if (offerWithClicksAndRebills) {
                    sortedFilteredOffers.push(offer);
                }
            });
            const beforeSearchInputAmount = sortedFilteredOffers.length;
            sortedFilteredOffers = sortedFilteredOffers.filter(offer => (offer.id + " " + offer.offerName).toLocaleLowerCase().includes(searchValue));
            this.setState({filteredOffers: sortedFilteredOffers, beforeSearchInputAmount: beforeSearchInputAmount, needToFilter: false});
            return sortedFilteredOffers;
        }
        const beforeSearchInputAmount = offers.length;
        offers = offers.filter(offer => (offer.id + " " + offer.offerName).toLocaleLowerCase().includes(searchValue));
        this.setState({filteredOffers: offers, beforeSearchInputAmount: beforeSearchInputAmount, needToFilter: false});
        return offers;
    };

    onCounterClick = (chartLine) => {
        const displayedLines = this.state.displayedLines;
        const index = displayedLines.indexOf(chartLine);
        if (index === -1) {
            displayedLines.push(chartLine);
        } else {
            displayedLines.splice(index, 1);
        }
        this.setState({displayedLines: displayedLines})
    };

    replaceBuyoutsWithSubscriptions = (areAllOffersBuyout) => {
        const displayedLine = areAllOffersBuyout ? "buyouts" : "subscriptions";
        const replacementLine = areAllOffersBuyout ? "subscriptions" : "buyouts";
        const displayedLines = this.state.displayedLines;
        const index = displayedLines.indexOf(replacementLine);
        if (index > -1) {
            displayedLines[index] = displayedLine;
            this.setState({displayedLines: [...displayedLines]})
        }

    };

    changeSearchValue = (value) => {
        this.setState({searchValue: value, needToFilter: true})
    };


    render() {

        const renderContent = () => {
            return (
                    <PageContent
                        displayContentHeader
                        displaySearchInput
                        onChangeSearchInput={this.changeSearchValue}
                        searchInputAmount={this.state.filteredOffers?.length}
                        beforeSearchInputAmount={this.state.beforeSearchInputAmount}
                        contentEl={
                            <div className="row w-100 nowrap" id="changeRow">
                                            <DashboardTrafficReportCard
                                                chartData={buildChartData(
                                                    this.props.chartData,
                                                    this.state.filteredOffers,
                                                    this.state.disabledOfferIds,
                                                    this.state.selectedOperatorIds,
                                                    this.state.toDate,
                                                    this.replaceBuyoutsWithSubscriptions
                                                )}
                                                isStatisticByDays={this.props.chartData && this.props.chartData.isStatisticByDays}
                                                selectedPeriod={this.state.selectedPeriod}
                                                onCloseDatePicker={this.onCloseDatePicker}
                                                isDatePickerHidden={this.props.loading}
                                                fromDate={this.state.fromDate}
                                                toDate={this.state.toDate}
                                                selectedOperatorIds={this.state.selectedOperatorIds}
                                                selectFromDate={this.selectFromDate}
                                                selectToDate={this.selectToDate}
                                                selectPeriod={this.selectPeriod}
                                                selectOperator={this.selectOperator}
                                                operators={this.props.operators}
                                                loading={this.props.loading}
                                                displayedLines={this.state.displayedLines}
                                                onCounterClick={this.onCounterClick}
                                                filteredOffers={this.state.filteredOffers}
                                                mainOffersSwitcherChecked={this.state.mainOffersSwitcherChecked}
                                                toggleMainOffersCheckbox={this.toggleMainOffersCheckbox}
                                                toggleOfferCheckbox={this.toggleOfferCheckbox}
                                                disabledOfferIds={this.state.disabledOfferIds}
                                            />
                                            <AdvertisingNetworksCard/>
                            </div>
                        }
                    >
                    </PageContent>
            )
        };

        return (
            <BasePage pageName="Дашборд" content={renderContent()}/>
        );
    }
};


function buildChartData(chartData, offers, disabledOfferIds = [], selectedOperatorIds = [], toDate, replaceBuyoutsWithSubscriptions) {
    if (!chartData || !offers) {
        return null;
    }
    const areAllOffersBuyout = offers.length > 0 && !offers.find(offer => offer.billingType !== SUBSCRIBE_1);
    replaceBuyoutsWithSubscriptions(areAllOffersBuyout);
    let labels = chartData.periods;
    let clicks = [], subscriptions = [], rebills = [], trafficbacks = [], cvr = [], income = [], buyouts = [];
    const statistic = chartData.items;
    statistic.forEach(function (traffic) {
        const offer = offers.find(function (offer) {
            return offer.id === traffic.offerId
                && (selectedOperatorIds.length === 0 || selectedOperatorIds.includes(offer.operatorId))
                && !disabledOfferIds.includes(offer.id);
        });
        if (offer) {
            sumTraffic(traffic.clicks, clicks);
            sumTraffic(traffic.subscriptions, subscriptions);
            sumTraffic(traffic.trafficbacks, trafficbacks);
            sumTraffic(traffic.rebills, rebills);
            sumTraffic(traffic.incomes, income);
            sumTraffic(traffic.buyouts, buyouts);
        }
    });
    cvr = calculateCvr(subscriptions, clicks, trafficbacks, buyouts, areAllOffersBuyout);
    income = income.map(item => Math.round(item));
    const isToday = isCurrentDay(toDate);
    const chartRebills = chartData.isStatisticByDays ? rebills : [];
    const chartIncomes = chartData.isStatisticByDays ? income : [];
    return {
        labels: labels,
        areAllOffersBuyout: areAllOffersBuyout,
        chartData: [
            {lineName: "clicks", lineData: clicks},
            {lineName: "subscriptions", lineData: subscriptions},
            {lineName: "rebills", lineData: chartRebills},
            {lineName: "trafficbacks", lineData: trafficbacks},
            {lineName: "cvr", lineData: cvr},
            {lineName: "income", lineData: chartIncomes},
            {lineName: "buyouts", lineData: buyouts},
        ],
        sum: {
            clicks: {amount: sum(clicks), dynamicPercentage: dynamicPercentage(clicks, isToday)},
            subscriptions: {amount: sum(subscriptions), dynamicPercentage: dynamicPercentage(subscriptions, isToday)},
            rebills: {amount: sum(rebills), dynamicPercentage: dynamicPercentage(rebills, isToday)},
            trafficbacks: {amount: sum(trafficbacks), dynamicPercentage: dynamicPercentage(trafficbacks, isToday)},
            cvr: {amount: average(cvr), dynamicPercentage: dynamicPercentage(cvr, isToday)},
            income: {amount: sum(income), dynamicPercentage: dynamicPercentage(income, isToday)},
            buyouts: {amount: sum(buyouts), dynamicPercentage: dynamicPercentage(buyouts, isToday)},
        }
    };
}

const sumTraffic = (arr1, arr2) => {
    arr1.forEach(function (element, index) {
        if (arr2[index] === undefined) {
            arr2.push(element);
        } else {
            arr2[index] += element;
        }
    });
};

const calculateCvr = (subscrArr, clicksArr, trafficBackArr, buyoutArr, isBuyoutCvr) => {
    const cvrs = [];
    const subscriptionsOrBuyouts = isBuyoutCvr ? buyoutArr : subscrArr;
    subscriptionsOrBuyouts.forEach((subscriptions, idx) => {
        const clicks = clicksArr[idx];
        const trafficbacks = trafficBackArr[idx];
        const successfulClicks = clicks - trafficbacks;
        let cvr;
        if (successfulClicks < 0) {
            cvr = 0.00;
        } else {
            cvr = successfulClicks > 0
                ? ((subscriptions / successfulClicks) * 100)
                : subscriptions > 0
                    ? 100.00
                    : 0.00;
        }
        cvr = Math.round(cvr * 100) / 100;
        cvrs.push(cvr);
    });
    return cvrs;
};

const sum = (array) => {
    var sum = 0;
    if (array !== undefined && array.length !== 0) {
        array.forEach(function (element) {
            sum += element;
        })
    }
    return sum;
};

const average = (array) => {
    if (!array || array.length === 0) {
        return 0.00;
    }
    const total = sum(array);
    return (total / array.length).toFixed(2);
};

const dynamicPercentage = (array, isToday) => {
    const firstElement = array[0];
    const endElement = isToday ? array[array.length - 2] : array[array.length - 1];
    return firstElement
        ? ((endElement - firstElement) / firstElement) * 100
        : endElement
            ? 100
            : 0;
};

function mapStateToProps(state) {
    return {
        chartData: state.dashboardReducer.chartData,
        offers: state.dashboardReducer.dashboardOffers,
        offersWithClicksOrRebills: state.dashboardReducer.chartData && state.dashboardReducer.chartData.items
            && state.dashboardReducer.chartData.items
                .map(item => {
                    return {
                        offerId: item.offerId,
                        clicks: sum(item.clicks),
                        rebills: sum(item.rebills),
                        subscriptions: sum(item.subscriptions)
                    }
                })
                .filter(item => item.clicks > 0 || item.rebills > 0),
        loading: state.dashboardReducer.loading,
        offersLoading: state.dashboardReducer.offersLoading,
        availablePartnerBalanceData: state.partnerBalanceReducer.availablePartnerBalanceData,
    }
}

export default connect(mapStateToProps, {
    getDashboardChartData,
    dashboardOffers,
    clearNews,
    loadAllActiveNetworks,
    loadAvailablePartnerBalance,
    loadFiltersData
})(DashboardPage);

