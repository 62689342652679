import React, {Fragment, useEffect, useState} from "react";
import {connect} from "react-redux";
import BasePage from "../../BasePage";
import {createLinks, loadPartnerOfferItem, loadPromotionLinks} from "../../../../actions/partnerOfferActions";
import {availableBillingTypesData, getIncome, getOfferNameWithId} from "../../../../utils/offerUtils";
import OfferItemInfoString from "./OfferItemInfoString";
import OfferItemImg from "./OfferItemImg";
import {loadPartnerProjectsWithNew, saveProject} from "../../../../actions/projectsActions";
import {loadUserPresets} from "../../../../actions/presetActions";
import MaterialUISelect from "../../../common/selects/MaterialUISelect";
import {presetsAccessType} from "../../../../utils/presetsUtils";
import {PARTNER_MACROS} from "../../../../utils/constants";
import useScreenSize from "../../../../hooks/useScreenSize";
import ProjectCreationCollapse from "../../../common/projectCreation/ProjectCreationCollapse";
import MacrosCollapse from "../macros/MacrosCollapse";
import OfferItemLink from "./OfferItemLink";
import {useHistory} from 'react-router-dom';
import {loadPartnerDomains} from "../../../../actions/partnerDomainsActions";
import PostbackTestModal from "../../projects/modal/PostbackTestModal";
import {sendPostbackTest} from "../../../../actions/postbackTestActions";
import {billingTypesData} from "../../../../utils/offerUtils";


function OfferItemPage(props) {
    const {loadPartnerOfferItem, offerItem, projects, domains, macrosPreset, loadPartnerProjectsWithNew, loadUserPresets,
        loadPartnerDomains, createLinks, sendPostbackTest, loadPromotionLinks, promotionLinks, isPromotionLinksLoading} = props;

    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [isFormExpanded, setIsFormExpanded] = useState(false);
    const [isTestPostbackExpanded, setIsTestPostbackExpanded] = useState(false);
    const [isPostbackTestSending, setIsPostbackTestSending] = useState(false);
    const [macros, setMacros] = useState({});
    const [link, setLink] = useState("");
    const [billingTypesDropdownData, setBillingTypesDropdownData] = useState([]);
    const [selectedBillingType, setSelectedBillingType] = useState(null);
    const history = useHistory();

    useEffect(() => {
        let offerId = window.location.pathname;
        offerId = offerId.split("/offers/item/");
        offerId = offerId && offerId.length > 0 && offerId[1];
        loadPartnerOfferItem(offerId);
        loadPartnerProjectsWithNew();
        loadPartnerDomains(true);
        const accessType = presetsAccessType();
        loadUserPresets(PARTNER_MACROS, accessType, null, () => {});
        if (!selectedProject || selectedProject.id === -1) {
            selectProject(projects[0])
        }

    }, []);

    useEffect(() => {
        if (!projects || projects.length === 0) {
            return;
        }
        selectProject(projects[1])
    }, [projects]);

    useEffect(() => {
        if (!selectedProject || !promotionLinks || promotionLinks.length === 0 || !selectedBillingType) {
            return;
        }
        const offerId = offerItem.offerIncomes.find(income => income.billingType === selectedBillingType.type)?.offerId;
        if (!offerId) {
            return;
        }
        const promotionLink = promotionLinks?.find(item => item.offerId === offerId && item.sourceId === selectedProject.sourceId)?.link;
        setLink(promotionLink ? promotionLink : "");
    }, [offerItem, promotionLinks, selectedBillingType]);

    useEffect(() => {
        if (domains) {
            const defaultDomain = domains.find(domain => domain.defaultDomain);
            selectDomain(defaultDomain ? defaultDomain : domains[0]);
        }
    }, [domains]);

    useEffect(() => {
        if (!offerItem) {
            return;
        }
        const offerIds = offerItem.offerIncomes.map(income => income.offerId);
        loadPromotionLinks(offerIds);
        let billingTypes = offerItem.offerIncomes?.map(income => income.billingType);
        const billingTypeDropdown = billingTypesData.filter(dataItem => billingTypes.includes(dataItem.type));
        setBillingTypesDropdownData(billingTypeDropdown);
        setSelectedBillingType(billingTypeDropdown[0]);
    }, [offerItem]);

    const screenSize = useScreenSize();

    const isMobile = screenSize.width <= 767;

    const selectDomain = (domain) => {
        setSelectedDomain(domain);
    };

    const selectProject = (project) => {
        if (project?.id === -1) {
            setIsFormExpanded(true);
        } else {
            setIsFormExpanded(false);
        }
        setSelectedProject(project);
        const promotionLink = promotionLinks?.find(item => item.sourceId === project?.sourceId)?.link;
        setLink(promotionLink ? promotionLink : "");
    };

    const hideForm = () => {
        setIsFormExpanded(false);
        setSelectedProject(projects[0]);
    };

    const returnToPrevPage = () => {
        history.goBack();
    };

    const createSimpleLink = (needToSaveMacros) => {
        if (!selectedProject || selectedProject.id === -1 || !selectedBillingType) {
            return;
        }
        const macrosBody = needToSaveMacros
            ? Object.assign({}, macros)
            : null;
        macrosBody && delete macrosBody.networkName;
        const offerId = offerItem.offerIncomes.find(income => income.billingType === selectedBillingType.type)?.offerId;
        if (!offerId) {
            return;
        }
        const offer = {offerId: offerId};
        createLinks([offer], selectedProject, macrosBody, () => loadPartnerOfferItem(offerItem.offerId));

    };

    const handleSendPostback = (postbackTest, handlePostbackTestResult) => {
        sendPostbackTest(postbackTest, handlePostbackTestResult);
    };

    const togglePostbackModalOpen = () => {
        setIsTestPostbackExpanded(!isTestPostbackExpanded);
    };

    const domainsEl = (style) => {
        return (
            <MaterialUISelect
                styles={style}
                dropdownData={domains}
                displayedFields={['id', 'cname']} dataField='id'
                placeholder="Выбрать домен"
                selectedValue={selectedDomain}
                onSelectCallbackFunction={(domain) => selectDomain(domain)}
                isSearchable={true}/>
        )
    };

    const channelEl = (style) => {
        return (
            <MaterialUISelect
                styles={style}
                dropdownData={projects}
                displayedFields={['sourceId', 'name']} dataField='id'
                placeholder="Выбрать поток"
                selectedValue={selectedProject}
                onSelectCallbackFunction={(project) => selectProject(project)}
                isSearchable={true}/>
        )
    };

    const billingTypeEl = (style) => {
        return (
            <MaterialUISelect
                styles={{root: {minWidth: 150}}}
                dropdownData={billingTypesDropdownData}
                displayedField='fullName' dataField='type'
                placeholder="Тип выплаты"
                selectedValue={selectedBillingType}
                onSelectCallbackFunction={(billingType) => setSelectedBillingType(billingType)}
                isSearchable={true}/>
        )
    };

    const btnsBlockEl = () =>
        <div className="select-potok">
        {!isFormExpanded && !link && selectedProject?.id >= 0 &&
        <button className="btn btn-outline-primary btn-margin" onClick={() => createSimpleLink(true)}>
            Получить ссылку
        </button>
        }
            {!isFormExpanded &&
            <button className="btn btn-outline-warning btn-margin" onClick={() => togglePostbackModalOpen()}>
                Проверить Postback
            </button>
            }
        </div>;


    const testPostbackModalEl = () =>
            <PostbackTestModal isOpened={isTestPostbackExpanded}
                               isSending={isPostbackTestSending}
                               offers={[offerItem]}
                               projects={projects}
                               selectedProject={selectedProject}
                               onCloseModal={togglePostbackModalOpen}
                               onSendPostback={handleSendPostback}
            />;

    const macrosCollapseEl = () => {
        return (
            !isFormExpanded && <MacrosCollapse macrosPreset={macrosPreset} macros={macros}/>
        )
    };

    const linkEl = () => {
        return (
            !isFormExpanded && <OfferItemLink link={link} isLinksLoading={isPromotionLinksLoading} selectedDomain={selectedDomain}/>
        )
    };

    const desktopContent = () => {
        if (isMobile) {
            return <></>
        }
        const style = {root: {minWidth: 150, padding: '0 10px 0 0'}};
        return (
            <>
                <div className="col-12 no-padding">
                    <hr/>
                    <div className="form-group">
                        {domainsEl(style)}
                        {channelEl(style)}
                        {billingTypeEl(style)}
                        {btnsBlockEl()}
                    </div>
                </div>
                <div className="col-12 no-padding">
                    <ProjectCreationCollapse isExpanded={isFormExpanded}
                                             onHideForm={hideForm}
                                             onSelectCreatedProject={selectProject}/>
                    {macrosCollapseEl()}
                    {linkEl()}
                </div>

            </>
        );
    };

    const mobileContent = () => {
        if (!isMobile) {
            return <></>
        }
        const style = {root: {minWidth: 250}};
        return (
            <>
                <div className="only-mob">
                    <hr/>
                    {domainsEl(style)}
                    {channelEl(style)}
                    <ProjectCreationCollapse isExpanded={isFormExpanded}
                                             onHideForm={hideForm}
                                             onSelectCreatedProject={selectProject}/>
                    {billingTypeEl(style)}
                    {macrosCollapseEl()}
                    {btnsBlockEl()}
                    {linkEl()}
                </div>

            </>
        );
    };

    const content = () => {
        if (!offerItem) {
            return null;
        }
        return (
            <>
                <div className="card no-mb ">
                    <div className="card-body">
                        <div className="plate w-100">
                            <div className="row w-100 nowrap scroll-content">
                                <div className="offer-page plate w-100"
                                     style={{padding: "0 5px 0 5px", height: !isMobile && '90vh'}}>
                                    <div>
                                        <div className="row single-offer-header">
                                            {getOfferNameWithId(offerItem.offerId, offerItem.name)}
                                            <div
                                                className="col-lg-1 col-md-2 col-sm-2 col-xs-12 right-buttons no_left_right_padding">
                                                <button className="circle-btn" title="Назад" onClick={returnToPrevPage}><i
                                                    className="ti ti-back-left"/>
                                                </button>
                                            </div>
                                        </div>

                                        <div className="row gap10">
                                            <OfferItemImg imgFileName={offerItem.imgFileName} className="" isMobile={isMobile}/>
                                            <div className="column" style={{maxWidth: "1000px"}}>
                                                <div className="more">
                                                    <OfferItemInfoString name="Страна" value={offerItem.countryName}/>
                                                    <OfferItemInfoString name="Оператор"
                                                                         value={offerItem.operatorName}/>
                                                    <OfferItemInfoString name="Категория"
                                                                         value={offerItem?.category.name}/>
                                                    {
                                                            availableBillingTypesData.map(billingTypeItem => {
                                                                const income = offerItem.offerIncomes.find(income => income.billingType === billingTypeItem.type);
                                                                return <Fragment key={billingTypeItem.type}>
                                                                    <OfferItemInfoString
                                                                        name={billingTypeItem.fullName}
                                                                        value={getIncome(income, offerItem)}/>
                                                                </Fragment>
                                                            })
                                                    }
                                                </div>
                                                {desktopContent()}
                                            </div>
                                        </div>
                                        {mobileContent()}
                                        {testPostbackModalEl()}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <BasePage
            pageName={"Оффер"}
            content={content()}/>
    )
}

const mapStateToProps = (state) => {
    return {
        offerItem: state.partnerOffers.offerItem,
        projects: state.projects.projects,
        domains: state.partnerDomainsReducer.partnerDomains,
        macrosPreset: state.preset.macrosPreset,
        promotionLinks: state.partnerOffers.promotionLinks,
        isPromotionLinksLoading: state.partnerOffers.isPromotionLinksLoading,
    }
};

export default connect(mapStateToProps, {
    loadPartnerOfferItem,
    loadPartnerProjectsWithNew,
    loadUserPresets,
    saveProject,
    createLinks,
    loadPartnerDomains,
    sendPostbackTest,
    loadPromotionLinks,
})(OfferItemPage);