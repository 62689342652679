
/*Роли авторизованных пользователей*/
export const ROLE_PARTNER = 'ROLE_PARTNER';
export const ROLE_ADMIN = 'ROLE_ADMIN';

/*Тип доступа к данным (accessType)*/
export const PARTNER = 'PARTNER';
export const MANAGER = 'MANAGER';

/*Типы пресетов*/
export const STATISTIC_TABLE_FILTERS = 'STATISTIC_TABLE_FILTERS';
export const STATISTIC_TABLE_COLUMNS = 'STATISTIC_TABLE_COLUMNS';
export const PARTNER_MACROS = 'PARTNER_MACROS';
export const PARTNER_AD_NETWORK_DATA = 'PARTNER_AD_NETWORK_DATA';
export const PARTNER_PAYMENT_DETAILS = 'PARTNER_PAYMENT_DETAILS';


/*Типы группировок*/
export const DATE_DAY = 'DATE_DAY'; /*По дням*/
export const DATE_HOUR = 'DATE_HOUR'; /*По часам*/
export const DATE_WEEK = 'DATE_WEEK'; /*По неделям*/
export const DATE_MONTH = 'DATE_MONTH'; /*По месяцам*/
export const PROJECT = 'PROJECT'; /*По потоку*/
export const SMART_LINK = 'SMART_LINK'; /*По смарт-линку*/
export const OFFER = 'OFFER'; /*По офферу*/
export const OFFER_V2 = 'OFFER_V2'; /*По офферу 2*/
export const SERVICE = 'SERVICE'; /*По группе сервисов*/
export const OPERATOR = 'OPERATOR';  /*По оператору*/
export const OFFER_CATEGORY = 'OFFER_CATEGORY'; /*По тематике*/
export const OFFER_BILLING_TYPE = 'OFFER_BILLING_TYPE'; /*По типу оффера*/
export const DEVICE_TYPE = 'DEVICE_TYPE'; /*По типу устройства*/
export const REFERER_DOMAIN = 'REFERER_DOMAIN'; /*'По домену*/
export const DEVICE_NAME = 'DEVICE_NAME'; /*По устройству*/
export const OS_NAME = 'OS_NAME'; /*По названию OC*/
export const OS = 'OS'; /*По версии OC*/
export const BROWSER = 'BROWSER'; /*По Браузеру*/
export const PT_ID = 'PT_ID'; /*По pt_id*/
export const PT_ID2 = 'PT_ID2'; /*По pt_id2*/
export const PT_ID3 = 'PT_ID3'; /*По pt_id3*/
export const SUB_ID = 'SUB_ID'; /*По pt_id3*/
export const IP_MASK = 'IP_MASK'; /*По диапазону IP*/
export const PARTNER_GROUP = 'PARTNER'; /*По партнёру*/
export const PARTNER_TYPE = 'PARTNER_TYPE'; /*По типу партнёра*/
export const CHANNEL = 'CHANNEL'; /*По каналу*/
export const UTILITY = 'UTILITY'; /*По услуге*/
export const UTILITY_TYPE = 'UTILITY_TYPE'; /*По типу услуги*/
export const SUBSCRIPTION_SERVICE = 'SUBSCRIPTION_SERVICE'; /*По подписочному сервису*/
export const SITE_ID = 'SITE_ID'; /*По параметру site_id*/
export const TRAF_SRC = 'TRAF_SRC'; /*По параметру trfaf_src*/
export const TB_STATUS = 'TB_STATUS'; /*По причине ТБ*/
export const LTV_SERVICE_TYPE = 'LTV_SERVICE_TYPE';

/*Типы периодов*/
export const TODAY = 'TODAY'; /*Сегодня*/
export const YESTERDAY = 'YESTERDAY'; /*Вчера*/
export const LAST_2_DAYS = 'LAST_2_DAYS'; /*2 дня*/
export const LAST_7_DAYS = 'LAST_7_DAYS'; /*7 дней*/
export const LAST_14_DAYS = 'LAST_14_DAYS'; /*14 дней*/
export const LAST_14_5_DAYS = 'LAST_14_5_DAYS'; /*14+5 дней*/
export const LAST_17_2_DAYS = 'LAST_17_2_DAYS'; /*17+2 дней - для планёрок по вторникам*/
export const LAST_30_DAYS = 'LAST_30_DAYS'; /*30 дней*/
export const LAST_30_5_DAYS = 'LAST_30_6_DAYS'; /*30+6 дней*/
export const CURRENT_WEEK = 'CURRENT_WEEK'; /*Текущая неделя*/
export const LAST_WEEK = 'LAST_WEEK'; /*Прошедшая неделя*/
export const CURRENT_MONTH = 'CURRENT_MONTH'; /*Текущий месяц*/
export const LAST_MONTH = 'LAST_MONTH'; /*Прошедший месяц*/


/*Типы статистических данных*/
export const CLICKS = "clicks";
export const SUBSCRIPTIONS = "subscriptions";
export const REBILLS = "rebills";
export const TRAFFICBACKS = "trafficbacks";
export const CVR = "cvr";
export const I_CLICKS = "iClicks";
export const BUYOUTS = "buyouts";
export const ACTIVE_AMOUNTS = "activeAmount";
export const UNSUBSCRIBES = "unsubscribes";
export const UNSUBSCRIBES_FACTOR = "unsubscribesFactor";
export const PERCENTAGE_OF_UNSUBSCRIBES = "percentageOfUnsubscribes";
export const INCOME = "income";
export const LEADS = "leads";
export const PAYMENTS_AMOUNT = "paymentsAmount";
export const PARTNER_PAYMENTS_AMOUNT = "partnersPaymentsAmount";
export const PRICE = "price";
export const OPERATOR_DEDUCTION_KOEFF = "operatorDeductionKoeff";
export const OPERATOR_KOEFF = "operatorKoeff";
export const PARTNER_KOEFF = "partnerKoeff";
export const ROYALTIES_KOEFF = "royaltiesKoeff";
export const GROSS_REVENUE = "grossRevenue";
export const INCOME_MINUS_OPERATORS_REVENUE = "incomeMinusOperatorsRevenue";
export const REFERRER_INCOME = "referrerIncome";
export const ROYALTIES_INCOME = "royaltiesIncome";
export const MARGIN = "margin";
export const REFERRALS_AMOUNT = "referralsAmount";

export const DAY_NUMBER = "dayNumber";
export const ROS = "ros";
export const PAYOUT_RATE = "payoutRate";
export const LTV75 = "ltv75";
export const LTV90 = "ltv90";
export const LTVX = "ltvX";
export const ROI75 = "roi75";
export const ROI90 = "roi90";
export const ROIX = "roiX";
export const MARGIN75 = "margin75";
export const MARGIN90 = "margin90";
export const MARGINX = "marginX";
export const BUYOUT_FACTOR = "buyoutFactor";
export const FINANCE_FEE = "financeFee";
export const FINANCE_FEE_AMOUNT = "financeFeeAmount";
export const EPC = "epc";
export const EPC_WITH_TB = "epcWithTb";

/*Тип отчета*/
export const STATISTIC_REPORT_TABLE = "STATISTIC_REPORT_TABLE";
export const STATISTIC_REPORT_CHART = "STATISTIC_REPORT_CHART";

/*Типы выплат*/
export const BILLING = "BILLING"; /* Выплаты за ребиллы */
export const BILLING_1 = "BILLING_1"; /* Выплаты за первый ребилл */
export const ICLICK = "ICLICK"; /* Разовая продажа (интернет-клик) */
export const SUBSCRIBE_1 = "SUBSCRIBE_1"; /* Выплаты за подписчика */

/*Статусы офферов*/
export const FOR_ACTIVATION = "FOR_ACTIVATION"; /* Для подключения */
export const DEPRECATED = "DEPRECATED"; /* Устаревшие */
export const CONNECTED = "CONNECTED"; /* Подключенные */

export const OFFER_STATUS_ACTIVE = "ACTIVE"; /* Активный */
export const OFFER_STATUS_INACTIVE = "INACTIVE"; /* Неактивный (отключенный) */




/*Табы страницы офферов*/
export const ACTIVE_OFFERS = "ACTIVE_OFFERS";
export const INACTIVE_OFFERS = "INACTIVE_OFFERS";
export const DEPRECATED_OFFERS = "DEPRECATED_OFFERS";
export const ALL_OFFERS = "ALL_OFFERS";
export const CONNECTED_OFFERS = "CONNECTED_OFFERS";
export const SMARTLINKS = "SMARTLINKS";
export const SMARTLINK_OFFERS_KIT_CHANGING_CONTENT = "SMARTLINK_OFFERS_KIT_CHANGING_CONTENT";
export const LINKS_AND_SMARTLINKS_CREATION_CONTENT = "LINKS_AND_SMARTLINKS_CREATION_CONTENT";
export const SMARTLINKS_EDITING_CONTENT = "SMARTLINKS_EDITING_CONTENT";
export const OFFER_EDIT_MAIN = "OFFER_EDIT_MAIN";
export const OFFER_EDIT_INCOMES = "OFFER_EDIT_INCOMES";
export const OFFER_BUYOUT_RULES = "OFFER_BUYOUT_RULES";

/*Тип события для постбека*/
export const POSTBACK_TYPE_BILLING = "BILLING"; /*Биллинг*/
export const POSTBACK_TYPE_SUBSCRIBE = "SUBSCRIBE"; /*Подписки*/
export const POSTBACK_TYPE_BUYOUTS = "SUBSCRIBE_WITH_DELAY"; /*Выкуп*/
export const POSTBACK_TYPE_UNSUBSCRIBE = "UNSUBSCRIBE"; /*Отписки*/

/*Тип сотрировки*/
export const ASC = "ASC"; /*Прямая*/
export const DESC = "DESC";  /*Обратная*/

/*Типы выплат*/
export const AD_NETWORK = "AD_NETWORK";
export const NONE = "NONE";
export const BANK_TRANSFER = "BANK_TRANSFER";
export const CAPITALIST = "CAPITALIST";
export const CONTRACT = "CONTRACT";
export const USDT = "USDT";
export const WEBMONEY = "WEBMONEY";
export const YANDEX_MONEY = "YANDEX_MONEY";

/*Табы в попапе на главнойстранице*/
export const LOGIN_FORM = "LOGIN_FORM";
export const REGISTRATION_FORM = "REGISTER_FORM";
export const RESTORE_PASSWORD_FORM = "RESTORE_PASSWORD_FORM";

/*Статусы выплат*/
export const CALCULATED = "CALCULATED";
export const CANCELED = "CANCELED";
export const CONFIRMATION = "CONFIRMATION"; //ожидание подтверждения от РС
export const PAID = "PAID";
export const PENDING = "PENDING";
export const TRANSFERRING_ERROR = "TRANSFERRING_ERROR"; //Ошибка отправки

/*Табы статистики*/
export const PARTNER_MAIN = "PARTNER_MAIN";
export const PARTNER_ANALYSIS = "PARTNER_ANALYSIS";
export const MANAGER_MAIN = "MANAGER_MAIN";
export const MANAGER_ANALYSIS = "MANAGER_ANALYSIS";
export const MANAGER_REFERRER = "MANAGER_REFERRER";
export const MANAGER_LTV_ANALYSIS = "MANAGER_LTV_V2";

export const LOCAL_STORAGE_STATISTIC_FILTERS = "STATISTIC_FILTERS";
export const LOCAL_STORAGE_OFFERS_FILTERS = "OFFERS_FILTERS";
export const LOCAL_STORAGE_THEME = "THEME";
export const LOCAL_STORAGE_IS_MENU_OPEN = "IS_MENU_OPEN";
export const LOCAL_STORAGE_IS_FILTERS_OPEN = "IS_FILTERS_OPEN";

/*Payment period*/
export const PAYMENT_PERIOD_WEEK = "WEEK";
export const PAYMENT_PERIOD_MONTH = "MONTH";

/*Themes*/
export const DARK_THEME = "darkTheme";
export const LIGHT_THEME = "lightTheme";
export const DARK_THEME_CLASS_NAME = "theme-dark";

/*Тип выплаты*/
export const VALUE = "VALUE"; //Фиксированная ставка в национальной валюте оператора
export const VALUE_RUB = "VALUE_RUB"; //Фиксированная ставка в рублях
export const PERCENT = "PERCENT";  //В % от EUP оффера

/*Тип выплаты*/
export const CALCULATION = "CALCULATION"; //Аванс/выплата, созданная менеджером
export const AD_NETWORK_CALCULATION = "AD_NETWORK_CALCULATION"; //Аванс/выплата в РС, созданная менеджером
export const AD_NETWORK_ORDER = "AD_NETWORK_ORDER"; //Вывод в рекламную сеть (партнером)
export const BALANCE_CORRECTION = "BALANCE_CORRECTION"; //Корректировка баланса
export const ORDER = "ORDER"; //Заказ
export const REGULAR = "REGULAR"; //Регулярное начисление

/*Табы финансов*/
export const ADVERTISING_NETWORKS = "ADVERTISING_NETWORKS";
export const MANAGER_PAYMENTS = "MANAGER_PAYMENTS";


/*Финкомиссия для расчёте ROI LTV*/
export const DEFAULT_FINANCE_FEE = "1.08";

/*Табы страницы FAQ*/
export const FAQ_TAB_COMMON_INFO = "FAQ_TAB_COMMON_INFO";
export const FAQ_TAB_BEGINNING = "FAQ_TAB_BEGINNING";
export const FAQ_TAB_OPERATOR_RESTRICTIONS = "FAQ_TAB_OPERATOR_RESTRICTIONS";
export const FAQ_TAB_PAYMENTS = "FAQ_TAB_PAYMENTS";
export const FAQ_TAB_CONTACTS = "FAQ_TAB_CONTACTS";

/*Табы страницы Tools*/
export const API_DOC_TAB_TRAFFIC_FILTERS = "API_DOC_TAB_TRAFFIC_FILTERS";
export const API_DOC_TAB_TRAFFICBACK = "API_DOC_TAB_TRAFFICBACK";
export const API_DOC_TAB_POSTBACK = "API_DOC_TAB_POSTBACK";
export const API_DOC_TAB_S2S = "API_DOC_TAB_S2S";
export const API_DOC_TAB_IPS = "API_DOC_TAB_IPS";
export const API_DOC_TAB_BLACK_LIST = "API_DOC_TAB_BLACK_LIST";
export const API_DOC_TAB_CAP = "API_DOC_TAB_CAP";
export const API_DOC_TAB_DOMAINS = "API_DOC_TAB_DOMAINS";
export const API_DOC_TAB_POSTBACK_RESENDING = "API_DOC_TAB_POSTBACK_RESENDING";
export const API_DOC_TAB_FILES = "API_DOC_TAB_FILES";
export const API_DOC_TAB_BANNERS = "API_DOC_TAB_BANNERS";
export const API_DOC_TAB_OTHER = "API_DOC_TAB_OTHER";
export const API_DOC_TAB_OPERATORS = "API_DOC_TAB_OPERATORS";
export const API_DOC_TAB_LOGS = "API_DOC_TAB_LOGS";
export const API_DOC_OTHER = "API_DOC_TAB_OTHER";
export const API_DOC_APP_PARAMS = "API_DOC_APP_PARAMS";

/*События PNS*/
export const PNS_BILLING = "BILLING";
export const PNS_BUYOUT = "BUYOUT";
export const PNS_SUBSCRIBE = "SUBSCRIBE";
export const PNS_UNSUBSCRIBE = "UNSUBSCRIBE";

/*Статусы переотправки постбеков*/
export const POSTBACK_NEW = "NEW";
export const POSTBACK_ACTIVE = "ACTIVE";
export const POSTBACK_DONE = "DONE";
export const POSTBACK_NO_DATA = "NO_DATA";

/*Тип рекламной сети (АПИ для обработки платежа)*/
export const BIGCLICK = "BIGCLICK";
export const MPAY = "MPAY";
export const UNKNOWN_API = "UNKNOWN";

/*Тип баннера*/
export const BANNER_AD_NETWORK = "BANNER_AD_NETWORK";
export const BANNER_TRACKER = "BANNER_TRACKER";

/*Тип логов*/
export const LOG_S2S = "S2S";

/*Тип фильтра*/
export const ACTIVE_PARTNER_OFFERS = "ACTIVE_PARTNER_OFFERS"; //Офферы партнера
export const BUYOUT_OFFERS = "BUYOUT_OFFERS"; //Выкупные офферы
export const COUNTRIES = "COUNTRIES"; //Страны
export const OFFER_CATEGORIES = "OFFER_CATEGORIES"; //Тематика
export const OPERATORS = "OPERATORS"; //Операторы
export const PARENT_OFFERS = "PARENT_OFFERS"; //Родительские офферы
export const PARTNERS = "PARTNERS"; //Партнеры
export const PARTNER_GROUPS = "PARTNER_GROUPS"; //Группы партнеров
export const SUBSCRIPTION_SERVICES = "SUBSCRIPTION_SERVICES"; //Сервисы
export const SUBSCRIPTION_SERVICE_GROUPS = "SUBSCRIPTION_SERVICE_GROUPS"; //Группы сервисов

