import React, {useEffect, useState} from "react";
import OperatorButton from "../../../common/buttons/OperatorButton";
import {useSelector} from "react-redux";
import {sort} from "../../../../utils/sortUtils";

export default function DashboardOperatorsFilter({selectOperator, selectedOperatorIds = [], className}) {

    const [operatorsWithClicks, setOperatorsWithClicks] = useState([]);

    const {operators} = useSelector(state => state.filterDataReducer.filterData);
    const chartData = useSelector(state => state.dashboardReducer.chartData);
    const offers = useSelector(state => state.dashboardReducer.dashboardOffers);

    useEffect(() => {
        if (!operators || !chartData || !offers) {
            return;
        }

        const byOfferIdOperatorMapping = offers.reduce((result, offer) => {
            result[offer.id] = offer.operatorId;
            return result;
        }, {});

        const byOperatorIdClicksMapping = chartData?.items?.reduce((result, chartItem) => {
            const clicksSum = chartItem.clicks?.reduce((a, b) => a + b, 0);
            const operatorId = byOfferIdOperatorMapping[chartItem.offerId];
            if (!operatorId) {
                return result;
            }
            if (result[operatorId]) {
                result[operatorId] = result[operatorId] + clicksSum;
            } else {
                result[operatorId] = clicksSum;
            }
            return result;
        }, {});

        let operatorsWithClicks = operators.filter(operator => byOperatorIdClicksMapping[operator.id]).map(operator => {return {...operator, clicks: byOperatorIdClicksMapping[operator.id]}});
        operatorsWithClicks = sort(operatorsWithClicks, "clicks", -1);
        if (operatorsWithClicks.length > 4) {
            operatorsWithClicks = operatorsWithClicks.splice(0, 4);
        } else if (operatorsWithClicks.length < 2) {
            operatorsWithClicks = [];
        }
        setOperatorsWithClicks(operatorsWithClicks);

    }, [operators, chartData, offers]);



    return (
        <div className={className}>
                        {
                            operatorsWithClicks.map(operator =>
                                <OperatorButton key={operator.id} isSelected={selectedOperatorIds.includes(operator.id)}
                                                fileName={operator.imgFileName} title={operator.name + ", " + operator.countryName}
                                                onClick={() => selectOperator(operator)}/>)
                        }
        </div>
    )
}
