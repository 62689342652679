import React, {Component} from "react";
import BasePage from "../BasePage";

import PartnerDomainsContent from "./PartnerDomainsContent";
import {connect} from "react-redux";
import {showWarning} from "../../../actions/warningActions";
import {loadToolsPageContent, savePageContent} from "../../../actions/pageContentActions";
import EditableTabContent from "../../common/htmlEditor/EditableTabContent";
import IpRangesContent from "./ipRanges/IpRangesContent";
import {URL_TOOLS} from "../../../properties";
import {pathParameter, replaceQueryParam} from "../../../utils/urlUtils";
import BlackListContent from "./blackList/BlackListContent";
import CapContent from "./cap/CapContent";
import {
    toolsPageAdminTabs,
    toolsPageTabsDataMapping,
    toolsPagPartnerTabs,
    toolsPageEditableTabs,
} from "../../../utils/pageTabsUtils";
import {
    API_DOC_TAB_FILES,
    API_DOC_TAB_BLACK_LIST,
    API_DOC_TAB_CAP,
    API_DOC_TAB_DOMAINS,
    API_DOC_TAB_IPS,
    API_DOC_TAB_POSTBACK_RESENDING,
    API_DOC_TAB_TRAFFIC_FILTERS,
    API_DOC_TAB_BANNERS,
    API_DOC_TAB_OTHER, API_DOC_TAB_OPERATORS,
    API_DOC_TAB_LOGS,
    API_DOC_APP_PARAMS
} from "../../../utils/constants";
import PostbackResendingContent from "./postbackResending/PostbackResendingContent";
import PageContent from "../../common/page/PageContent";
import FilesContent from "./files/FilesContent";
import BannersContent from "./banner/BannersContent";
import OtherToolsContent from "./other/OtherToolsContent";
import OperatorsToolsContent from "./operators/OperatorsToolsContent";
import LogsContent from "./logs/LogsContent";
import AppParamsContent from "./appParams/AppParamsContent";


class ToolsPage extends Component {

    state = {
        initValue: null,
        selectedContent: {},
        selectedTab: API_DOC_TAB_TRAFFIC_FILTERS,
        isNewTabSelected: true,
        isEditorOpened: false,
    };

    componentDidMount() {
        this.props.loadToolsPageContent();
        let pathContentType = pathParameter("type");
        pathContentType = pathContentType ? pathContentType : "filters";
        let selectedTab = Object.keys(toolsPageTabsDataMapping).find(tabName => toolsPageTabsDataMapping[tabName].searchParam === pathContentType);
        selectedTab = selectedTab ? selectedTab : API_DOC_TAB_TRAFFIC_FILTERS;
        this.setState({selectedTab: selectedTab});
        this.props.history.push({
            pathname: URL_TOOLS,
            search: replaceQueryParam("type", toolsPageTabsDataMapping[selectedTab].searchParam, window.location.search)
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.pageContent !== prevProps.pageContent) {
            const contentType = this.state.selectedTab && toolsPageTabsDataMapping[this.state.selectedTab].contentType;
            const selectedContent = this.props.pageContent.find(pageContent => pageContent.contentType === contentType);
            if (selectedContent) {
                this.setState({...this.state, selectedContent: selectedContent, initValue: selectedContent.content})
            }
        }
    }

    toggleEditorOpened = () => {
        this.setState({...this.state, isEditorOpened: !this.state.isEditorOpened})
    };

    selectTab = (tabName) => {
        const contentType = tabName && toolsPageTabsDataMapping[tabName].contentType;
        let newEditableContent = this.props.pageContent.find(pageContent => pageContent.contentType === contentType);
        newEditableContent = newEditableContent ? newEditableContent : {};
        if (this.state.hasChanges) {
            this.props.showWarning("Имеются несохраненные изменения. Сохранить?",
                () => { this.props.savePageContent(this.state.selectedContent, () => this.setOnSelectTabState(tabName, newEditableContent));},
                () => this.setOnSelectTabState(tabName, newEditableContent)
            );
        } else {
            this.setOnSelectTabState(tabName, newEditableContent);
        }
    };

    setOnSelectTabState = (tabName, newEditableContent) => {
        this.props.history.push({
            pathname: URL_TOOLS,
            search: replaceQueryParam("type", toolsPageTabsDataMapping[tabName].searchParam, window.location.search)
        });
        this.setState({
            ...this.state,
            selectedTab: tabName,
            hasChanges: false,
            selectedContent: newEditableContent,
            initValue: newEditableContent.content,
            isNewTabSelected: true,
            isEditorOpened: false
        });
    };


    saveContent = () => {
        this.props.savePageContent(this.state.selectedContent, () => this.setState({...this.state, hasChanges: false, isEditorOpened: false}));
    };


    handleChangeContent = (value) => {
        if (!this.state.isNewTabSelected && this.state.selectedContent.content !== value) {
            this.setState({
                ...this.state,
                hasChanges: true,
                selectedContent: {...this.state.selectedContent, content: value},
                isNewTabSelected: false
            });
        } else {
            this.setState({...this.state, isNewTabSelected: false})
        }
    };

    renderContent = () => {
        const {selectedTab, selectedContent, hasChanges, isEditorOpened} = this.state;
        return (
            <>
                    {toolsPageEditableTabs.includes(selectedTab) &&
                    <EditableTabContent
                        value={selectedContent?.content}
                        handleChangeContent={this.handleChangeContent}
                        handleSave={hasChanges && this.saveContent}
                        toggleEditorOpened={this.toggleEditorOpened}
                        isEditorOpened={isEditorOpened}
                    />}
                    {selectedTab === API_DOC_TAB_IPS && <IpRangesContent/>}
                    {selectedTab === API_DOC_TAB_BLACK_LIST && <BlackListContent/>}
                    {selectedTab === API_DOC_TAB_DOMAINS && <PartnerDomainsContent/>}
                    {selectedTab === API_DOC_TAB_CAP && <CapContent/>}
                    {selectedTab === API_DOC_TAB_POSTBACK_RESENDING && <PostbackResendingContent/>}
                    {selectedTab === API_DOC_TAB_FILES && <FilesContent/>}
                    {selectedTab === API_DOC_TAB_BANNERS && <BannersContent/>}
                    {selectedTab === API_DOC_TAB_OPERATORS && <OperatorsToolsContent/>}
                    {selectedTab === API_DOC_TAB_LOGS && <LogsContent/>}
                    {selectedTab === API_DOC_APP_PARAMS && <AppParamsContent/>}
                    {selectedTab === API_DOC_TAB_OTHER && <OtherToolsContent/>}


            </>
        )
    };


    render() {
        const selectedTab = this.state.selectedTab;
        const tabs = this.props.auth.isPartner || this.props.auth.isAdminAsPartner ? toolsPagPartnerTabs : toolsPageAdminTabs;
        return (
            <BasePage pageName="Разное"
    content={
        <PageContent
            displayContentHeader
            tabs={tabs}
            selectedTabName={selectedTab}
            onSelectTab={this.selectTab}
            contentEl={this.renderContent()}
        />
    }/>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        pageContent: state.pageContentReducer.pageContent,
        error: state.error.data,
        auth: state.auth,
    }
};

export default connect(mapStateToProps, {showWarning, loadToolsPageContent, savePageContent})(ToolsPage);