import React, {Component} from 'react';
import PropTypes from 'prop-types';
import OfferCardNew from "./offerCard/OfferCardNew";
import {
    ALL_OFFERS,
    SMARTLINK_OFFERS_KIT_CHANGING_CONTENT,
} from "../../../utils/constants";
import Spinner from "../../common/Spinner";
import NoContentMessage from "../../common/NoContentMessage";
import PartnerOfferFilters from "./filters/offers/PartnerOfferFilters";
import PageContent from "../../common/page/PageContent";
import OffersContactsModal from "./modal/OffersContactsModal";
import {isAdminAsPartner, isPartner} from "../../../utils/authUtils";
import ManagerOfferFilters from "./filters/offers/ManagerOfferFilters";
import SelectedOffersPopup from "./SelectedOffersPopup";
import {URL_OFFER_ITEM} from "../../../properties";


export default class OffersCardsGridContent extends Component {
    static propTypes = {
        tabs: PropTypes.object,
        selectedNavTab: PropTypes.string,
        selectNavTab: PropTypes.func,
        selectedOfferIds: PropTypes.array,
        selectedOffers: PropTypes.array,
        onSelectOfferCard: PropTypes.func,
        displayedComponent: PropTypes.oneOf([ALL_OFFERS, SMARTLINK_OFFERS_KIT_CHANGING_CONTENT]),
        searchInputValue: PropTypes.string,
        beforeSearchInputAmount: PropTypes.any,
        filteredOffers: PropTypes.array,
        filters: PropTypes.object,
        resetAllFilters: PropTypes.func,
        toggleCheckboxFilter: PropTypes.func,
        onSelectFilterValue: PropTypes.func,
        onChangeSearchInput: PropTypes.func,
        filterOffers: PropTypes.func,
        needToFilter: PropTypes.bool,
        isContactsModalOpened: PropTypes.bool,
        isGrayCards: PropTypes.bool,
        displayIpRangesBtn: PropTypes.bool,
        closeContactsModal: PropTypes.func,
        onClickAdditionalEl: PropTypes.func,
        searchInputAmount: PropTypes.any,
        popupImg: PropTypes.any,
    };

    static defaultProps = {
        selectedOfferIds: []
    };

    state = {
        offersForRendering: [],
        componentMounted: false,
        drawableOffers: 0,
    };


    componentDidMount() {
        const offerListElement = document.getElementById('offersCards');
        let drawableOffers;
        if (offerListElement) {
            let offersInRow = offerListElement.offsetWidth / 195; //офферов в 1 ряду
            offersInRow = offersInRow - offersInRow % 1; //округляем до целого вниз
            drawableOffers = offersInRow * 6; //отрисовываем 3 ряда за 1 раз
        }
        this.setState({
            drawableOffers: drawableOffers,
            componentMounted: true
        });

    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const allOffers = this.props.allOffers;
        if ((allOffers && allOffers.length > 0 && this.props.needToFilter)
            || (this.props.searchInputValue !== prevProps.searchInputValue)) {
            this.filterOffers();
        }
    }

    handleScroll = (el) => {
        if (el.clientHeight + el.scrollTop < (el.scrollHeight - 300)) {
            return;
        }
        let endIdx = this.state.offersForRendering.length + this.state.drawableOffers;
        if (!this.props.filteredOffers[endIdx]) {
            endIdx = this.props.filteredOffers.length;
        }
        this.setState({
            offersForRendering: this.props.filteredOffers.slice(0, endIdx)
        });
    };

    filterOffers = () => {
        const filteredOffers = this.props.filterOffers(this.props.filters.isOnlySelectedOffers ? this.props.selectedOffers : this.props.allOffers);
        this.setState({
            offersForRendering: filteredOffers && filteredOffers.length >= this.state.drawableOffers
                ? filteredOffers.slice(0, this.state.drawableOffers)
                : filteredOffers,
        });
    };

    renderFiltersEl = () => {
        const isPartnerPage = isPartner() || isAdminAsPartner();
        return isPartnerPage
            ? <PartnerOfferFilters key={"1"}
                                   offers={this.props.isOnlySelectedOffers ? this.state.selectedOffers : this.props.allOffers}
                                   displayedComponent={this.props.displayedComponent}
                                   filters={this.props.filters}
                                   resetAllFilters={this.props.resetAllFilters}
                                   toggleCheckboxFilter={this.props.toggleCheckboxFilter}
                                   onSelectFilterValue={this.props.onSelectFilterValue}/>
            :
            <ManagerOfferFilters offers={this.props.isOnlySelectedOffers ? this.state.selectedOffers : this.props.allOffers}
                                 toggleCheckboxFilter={this.props.toggleCheckboxFilter}
                                 onSelectFilterValue={this.props.onSelectFilterValue}
                                 filters={this.props.filters}
                                 resetAllFilters={this.props.resetAllFilters}
            />
    };


    render() {
                return (
                        <PageContent
                                    tabs = {this.props.tabs}
                                    selectedTabName = {this.props.selectedNavTab}
                                    onSelectTab = {this.props.selectNavTab}
                                    onScroll = {this.handleScroll}
                                    displaySearchInput
                                    onChangeSearchInput = {this.props.onChangeSearchInput}
                                    searchInputAmount = {this.props.searchInputAmount}
                                    searchInputValue = {this.props.searchInputValue}
                                    beforeSearchInputAmount={this.props.beforeSearchInputAmount}
                                    displayContentHeader
                                    filtersEl = {this.renderFiltersEl()}
                                    displayFiltersBtn
                                    displayIpRangesBtn
                                    contentEl = {
                                                    <div className="custom-tab" id="offersCards">
                                                        {
                                                            this.props.isLoading
                                                                ?
                                                                <Spinner/>
                                                                :
                                                                <>
                                                                    {
                                                                        this.state.offersForRendering && this.state.offersForRendering.length > 0
                                                                            ?
                                                                            <section id="offers-list">
                                                                                {this.state.offersForRendering.map((offer) =>
                                                                                    <OfferCardNew key={offer.offerId} offer={offer} offerIncomes={offer.offerIncomes}
                                                                                               isChecked={this.props.selectedOfferIds.includes(offer.offerId)}
                                                                                               onSelect={this.props.onSelectOfferCard}
                                                                                               isGray={this.props.isGrayCards}/>)
                                                                                }
                                                                            </section>
                                                                            : <NoContentMessage/>
                                                                    }
                                                                </>
                                                        }
                                                    </div>
                                                    }
                                    additionalEl={<SelectedOffersPopup amount={this.props.selectedOfferIds && this.props.selectedOfferIds.length}
                                                                       onClick={this.props.onClickAdditionalEl}
                                                                       img={this.props.popupImg}
                                                                       toggleOnlySelected={() => this.props.toggleCheckboxFilter("isOnlySelectedOffers")}
                                    />}
                        >
                        <OffersContactsModal isOpen = {this.props.isContactsModalOpened} onCloseModal = {this.props.closeContactsModal}/>
                        </PageContent>
                         )
            }

}