import React from "react";
import LineChart from "../../common/charts/LineChart";
import DashboardDateRangePicker from "./filters/DashboardDateRangePicker";
import DashboardOperatorsFilter from "./filters/DashboardOperatorsFilter";
import DashboardPeriodsFilter from "./filters/DashboardPeriodsFilter";
import DashboardTotalSumBox from "./totalSumBox/DashboardTotlalSumBox";
import Spinner from "../../common/Spinner";
import {getLineColor} from "../../../utils/chartUtils";
import useScreenSize from "../../../hooks/useScreenSize";
import DashboardOffersCard from "./dashboardOffers/DashboardOffersCard";


export default function DashboardTrafficReportCard(props) {
    const {
        chartData, selectedPeriod, fromDate, toDate, selectedOperatorIds, displayedLines, selectFromDate, selectToDate,
        selectPeriod, onCloseDatePicker, isDatePickerHidden, selectOperator, operators, loading, onCounterClick, isStatisticByDays, filteredOffers,
        disabledOfferIds, mainOffersSwitcherChecked, toggleMainOffersCheckbox, toggleOfferCheckbox
    } = props;

    const counters = chartData && [
        {
            counterName: 'Клики',
            value: chartData.sum.clicks.amount.toLocaleString("ru-RU"),
            dynamicPercentage: chartData.sum.clicks.dynamicPercentage,
            lineName: "clicks",
            iconTooltip: "Отношение количества кликов в конце периода к количеству кликов в начале периода без учёта текущего дня/часа"
        },
        {
            counterName: 'Trafficback',
            value: chartData.sum.trafficbacks.amount.toLocaleString("ru-RU"),
            dynamicPercentage: chartData.sum.trafficbacks.dynamicPercentage,
            isReverseDynamic: true,
            lineName: "trafficbacks",
            iconTooltip: "Отношение количества ТБ в конце периода к количеству ТБ в начале периода без учёта текущего дня/часа"
        },
        chartData.areAllOffersBuyout ? {
            counterName: 'Выкупы',
            value: chartData.sum.buyouts.amount.toLocaleString("ru-RU"),
            dynamicPercentage: chartData.sum.buyouts.dynamicPercentage,
            lineName: "buyouts",
            iconTooltip: "Отношение количества выкупов в конце периода к количеству подписок в начале периода без учёта текущего дня/часа"
        } : {
            counterName: 'Подписки',
            value: chartData.sum.subscriptions.amount.toLocaleString("ru-RU"),
            dynamicPercentage: chartData.sum.subscriptions.dynamicPercentage,
            lineName: "subscriptions",
            iconTooltip: "Отношение количества подписок в конце периода к количеству подписок в начале периода без учёта текущего дня/часа"
        },
        {
            counterName: 'CR',
            value: chartData.sum.cvr.amount  + "%",
            dynamicPercentage: chartData.sum.cvr.dynamicPercentage,
            lineName: "cvr",
            iconTooltip: "Отношение CR в конце периода к CR в начале периода без учёта текущего дня/часа"
        },
        {
            counterName: 'Ребиллы',
            value: chartData.sum.rebills.amount.toLocaleString("ru-RU"),
            dynamicPercentage: isStatisticByDays ? chartData.sum.rebills.dynamicPercentage : 0,
            lineName: "rebills",
            iconTooltip: "Отношение количества ребиллов в конце периода к количеству ребиллов в начале периода без учёта текущего дня/часа"
        },
        {
            counterName: 'Доход',
            value: chartData.sum.income.amount.toLocaleString("ru-RU") + "p.",
            dynamicPercentage: isStatisticByDays ?  chartData.sum.income.dynamicPercentage : 0,
            lineName: "income",
            iconTooltip: "Отношение дохода в конце периода к доходу в начале периода без учёта текущего дня/часа"
        },
    ];

   const screenSize = useScreenSize();
    const height = screenSize.width > 990 && document.getElementById("dashboardOffersCard") && document.getElementById("dashboardOffersCard").clientHeight;

    return (
        <div className="chart-block plate">
            <div className="chart-screen">
                {loading
                    ? <div style={{paddingTop: "100px"}}><Spinner/></div>
                    : chartData && <LineChart {...chartData} isLoading={false}
                                              displayedLines={displayedLines}/>}
            </div>
            <div className="row grid6">
                {counters && counters.map(counter => <DashboardTotalSumBox key={counter.counterName}
                                                                           name={counter.counterName}
                                                                           value={counter.value}
                                                                           dynamicPercentage={counter.dynamicPercentage}
                                                                           isReverseDynamic={counter.isReverseDynamic}
                                                                           onCounterClick={() => onCounterClick(counter.lineName)}
                                                                           disabled={!displayedLines.includes(counter.lineName)
                                                                           || (!isStatisticByDays && (counter.lineName === "rebills" || counter.lineName === "income"))}
                                                                           lineColor={getLineColor(counter.lineName)}
                                                                           iconTooltip={counter.iconTooltip}
                />)}
            </div>
            <div className="row">
                <div className="chart-ui">
                    <DashboardDateRangePicker
                        fromDate={fromDate}
                        toDate={toDate}
                        selectFromDate={selectFromDate}
                        selectToDate={selectToDate}
                        onCloseDatePicker={onCloseDatePicker}
                        hidden={isDatePickerHidden}
                        className="date-block"/>
                    <DashboardPeriodsFilter
                        selectedPeriod={selectedPeriod}
                        selectPeriod={selectPeriod}
                        className="datelist"
                    />
                    <DashboardOperatorsFilter
                        operators={operators}
                        selectOperator={selectOperator}
                        selectedOperatorIds={selectedOperatorIds}
                        className="operator-list"
                    />
                </div>
            </div>
            <div className="row">
            <DashboardOffersCard
                offers={filteredOffers}
                mainOffersSwitcherChecked={mainOffersSwitcherChecked}
                toggleMainOffersCheckbox={toggleMainOffersCheckbox}
                toggleOfferCheckbox={toggleOfferCheckbox}
                disabledOfferIds={disabledOfferIds}
                />
            </div>
        </div>
    );
}




